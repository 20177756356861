document.addEventListener('DOMContentLoaded', function () {
  const emailInput = document.getElementById('emailNewsletter');
  const submitButton = document.getElementById('submitNewsletter');

  emailInput.addEventListener('input', function () {
    if (emailInput.validity.valid) {
      submitButton.removeAttribute('disabled');
    } else {
      submitButton.setAttribute('disabled', 'true');
    }
  });

  submitButton.addEventListener('click', function () {
    newsletter.show();
  });
});
